import { Vertex } from "../../Classes/Vertex_class";

export const Random_Integer = (min:number, max:number):number => 
{
	return ( Math.floor(Math.random() * (max - min + 1) ) + min );
};

export const Random_Float = (min:number, max:number):number => 
{
	return ( Math.random() * (max - min + 0.1) + min );
};

export const Size_Selector = (layer:number):number => 
{
	if (layer === 1)
		return (Random_Integer(3, 4));
	else if (layer === 2)
		return (Random_Integer(2, 3));
	else if (layer === 3)
		return (Random_Integer(1, 2));

	return (0);
};

export const Color_Selector = (layer:number):string =>
{
	let transparency:number;

	if (layer === 1)
		transparency = Random_Float(0.7, 1);
	else if (layer === 2)
		transparency = Random_Float(0.5, 0.7);
	else if (layer === 3)
		transparency = Random_Float(0.4, 0.5);
	else 
		transparency = 0;

	return ("rgba(255, 255, 255, " + transparency + ")");
};

export const Cpy_Vertex = (src_vertex:Vertex, dest_vertex:Vertex) => 
{
	dest_vertex.id = src_vertex.id;
	dest_vertex.coord.x = src_vertex.coord.x;
	dest_vertex.coord.y = src_vertex.coord.y;
	dest_vertex.layer = src_vertex.layer;
	dest_vertex.color = src_vertex.color;
	dest_vertex.size = src_vertex.size;
	dest_vertex.in_subgraph = src_vertex.in_subgraph;
	dest_vertex.text_to_display = src_vertex.text_to_display;
	dest_vertex.is_an_hypergraph = src_vertex.is_an_hypergraph;
};