import { Graph } from "../Classes/Graph_class";
import { Subgraph } from "../Classes/Subgraph_class";
import { Vertex } from "../Classes/Vertex_class";
import { Vector2D } from "../Types/graphAnimation_types";
import { Get_Acceleration } from "./Utils/motion_physic";
import { Get_Unit_Vector, Get_Vector_Norm, Mult_Vector_Scalar } from "./Utils/vector_utils";

let prev_mouse_x:number = 0;
let prev_mouse_y:number = 0;
let mouse_move_delta_x:number;
let mouse_move_delta_y:number;

export const Mouse_Move_Event = (e:MouseEvent, graph:Graph, subgraph:Subgraph) => 
{   
	if (graph && subgraph)
	{
		mouse_move_delta_x = e.pageX - prev_mouse_x;
		if (e.pageY - prev_mouse_y > 0)
			mouse_move_delta_y = (e.pageY - prev_mouse_y) % 20;
		else if (e.pageY - prev_mouse_y < 0)
			mouse_move_delta_y = (e.pageY - prev_mouse_y) % (-20);
		prev_mouse_x = e.pageX;
		prev_mouse_y = e.pageY;	
		Updates_Entities_To_Mouse_Move_Event(graph, subgraph);
	}
};

	const Updates_Entities_To_Mouse_Move_Event = (graph:Graph, subgraph:Subgraph) => 
	{
		Update_Vertices_Graph_To_Mouse_Move_Event(graph);
		Move_Vertex(subgraph.center_of_collapse);

		if (subgraph.start_collapse)
			Ajust_Velocity_Vector_To_Mouse_Move_Event(subgraph);
	};

		const Update_Vertices_Graph_To_Mouse_Move_Event = (graph:Graph) => 
		{
			for (const vertex of graph.graph_vertices)
				Move_Vertex(vertex);
		};

		const Move_Vertex = (vertex:Vertex) => 
		{
			if (vertex.layer === 1)
			{
				vertex.coord.x -= mouse_move_delta_x * 0.3;
				vertex.coord.y -= mouse_move_delta_y * 0.6;
			}
			else if (vertex.layer === 2)
			{
				vertex.coord.x -= mouse_move_delta_x * 0.2;
				vertex.coord.y -= mouse_move_delta_y * 0.4;
			}  
			else
			{
				vertex.coord.x -= mouse_move_delta_x * 0.1;
				vertex.coord.y -= mouse_move_delta_y * 0.2;
			}	
		};

		const Ajust_Velocity_Vector_To_Mouse_Move_Event = (subgraph:Subgraph) =>
		{
			for (const vertex of subgraph.subgraph_vertices)
			{
				const vertex_to_center:Vector2D = { x: subgraph.center_of_collapse.coord.x - vertex.coord.x, 
													y: subgraph.center_of_collapse.coord.y - vertex.coord.y };
				const acceleration:Vector2D = Get_Acceleration(subgraph.center_of_collapse, vertex_to_center);
				const velocity_norm:number = Get_Vector_Norm(vertex.collapse_velocity);
				
				vertex.collapse_velocity = Mult_Vector_Scalar(Get_Unit_Vector(vertex_to_center), velocity_norm);
				vertex.collapse_velocity.x += acceleration.x;
				vertex.collapse_velocity.y += acceleration.y;
			}
		};