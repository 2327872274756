import { Edge } from "../Classes/Edge_class";
import { Graph } from "../Classes/Graph_class";
import { Subgraph } from "../Classes/Subgraph_class";
import { Vertex } from "../Classes/Vertex_class";
import { Vector2D } from "../Types/graphAnimation_types";
import { Build_Graph, Build_Subgraph, Init_Graph_Vertex_Data } from "./entities_building";
import { Get_Acceleration } from "./Utils/motion_physic";
import { Color_Selector, Cpy_Vertex, Random_Integer, Size_Selector } from "./Utils/utils";
import { Add_Vectors, Get_Vector_Norm, Vector_Are_Equals } from "./Utils/vector_utils";

const EDGE_MAX_DRAWING_STEPS:number = 100;
const TIMER_FADE_GRAPH_MAX:number = 9000;
const TIMER_FADE_GRAPH_MIN:number = 6000;
const ZONE_CANCEL_VELOCITY:number = 50;
const ZONE_CHANGE_VERTEX_VIEW:number = 50;
const AMOUNT_OF_LAYER:number = 3;
const PERCENT_VERTEX:number = 15;
const PERCENT_EDGES:number = 10;


export const animate = (canvas_context:CanvasRenderingContext2D, graph:Graph, subgraph:Subgraph) => 
{
	requestAnimationFrame(() => animate(canvas_context, graph, subgraph));
	canvas_context.clearRect(0, 0, canvas_context.canvas.width, canvas_context.canvas.height);

	if (All_Edges_completed(subgraph.subgraph_edges))
	{
		//Preparing Collapse
		if (subgraph.center_of_collapse_already_selected === false)
            Preparing_Collapse(subgraph);

		//During Collapse
		if (subgraph.start_collapse)
            During_Collapse(canvas_context, subgraph);

		//After Collapse
        if (All_Vertices_Collapsed(subgraph.subgraph_vertices, subgraph.center_of_collapse))
        {
            let hypergraph:Vertex = new Vertex(0);
            
            Cpy_Vertex(subgraph.center_of_collapse, hypergraph);
            hypergraph.hypergraph_ring_size = hypergraph.size + Random_Integer(5, 10);

            graph.graph_vertices.push(hypergraph);
            graph = Build_Graph(graph);
            subgraph = Build_Subgraph(graph, subgraph, PERCENT_VERTEX, PERCENT_EDGES);

            for (const vertex of graph.graph_vertices)
                if (vertex.in_subgraph === false && Vector_Are_Equals(vertex.coord, {x: 0, y: 0}))
                    Init_Graph_Vertex_Data(vertex);
        }    
	}
	else
		Update_Edge_State(subgraph); //During subgraph completion
		
	//Continualy Drawing The Entitites
    if (graph)
    {
        for (const vertex of graph.graph_vertices)
        {
            if (vertex.is_an_hypergraph === true)
                Draw_Halo(canvas_context, vertex);

            Draw_Vertex(canvas_context, vertex);
        }
    }	

	if (subgraph)
		for (const edge of subgraph.subgraph_edges)
			Draw_Edge(canvas_context, edge);
};

    const All_Edges_completed = (edge_arr:Edge[]):boolean => 
    {
        for (const edge of edge_arr)
            if (edge.edge_complete === false)
                return (false);

        return (true);
    };

    const Preparing_Collapse = (subgraph:Subgraph) =>
    {
        Init_Collapse_Vertex_Data(subgraph);

        subgraph.center_of_collapse_already_selected = true;
        setTimeout(() => { subgraph.start_collapse = true; }, 8000);
    };

        const Init_Collapse_Vertex_Data = (subgraph:Subgraph) => 
        {
            subgraph.center_of_collapse.coord = Get_Subgraph_Average_Vertex_Vector2D(subgraph);
            subgraph.center_of_collapse.layer = Random_Integer(1, AMOUNT_OF_LAYER);
            subgraph.center_of_collapse.color = Color_Selector(subgraph.center_of_collapse.layer);
            subgraph.center_of_collapse.size = Size_Selector(subgraph.center_of_collapse.layer);
            subgraph.center_of_collapse.is_an_hypergraph = true;
        };

            const Get_Subgraph_Average_Vertex_Vector2D = (subgraph:Subgraph):Vector2D =>
            {
                const average_vertex_coord:Vector2D = Sum_Vertex_Array_Vector2D(subgraph.subgraph_vertices);
            
                average_vertex_coord.x /= subgraph.subgraph_vertices.length;
                average_vertex_coord.y /= subgraph.subgraph_vertices.length;
            
                return (average_vertex_coord);
            }

                const Sum_Vertex_Array_Vector2D = (vertex_arr:Vertex[]):Vector2D => 
                {
                    let sum_arr_value:Vector2D = {x: 0, y: 0};
                
                    for (const vertex of vertex_arr)
                    {
                        sum_arr_value.x += vertex.coord.x;
                        sum_arr_value.y += vertex.coord.y;
                    }
                
                    return (sum_arr_value);
                };



    const During_Collapse = (canvas_context:CanvasRenderingContext2D, subgraph:Subgraph) => 
    {
        Update_Vertices_For_Collapse(subgraph);
        if (Vertex_Already_Collapse(subgraph))
            Draw_Vertex(canvas_context, subgraph.center_of_collapse)
    };

        const Update_Vertices_For_Collapse = (subgraph:Subgraph) => 
        {
            for (const vertex of subgraph.subgraph_vertices)
            {
                const vertex_to_center:Vector2D = { x: subgraph.center_of_collapse.coord.x - vertex.coord.x, 
                                                    y: subgraph.center_of_collapse.coord.y - vertex.coord.y };

                Update_Vertices_View_In_Collpase(vertex, subgraph.center_of_collapse, vertex_to_center);

                if (Get_Vector_Norm(vertex_to_center) > ZONE_CANCEL_VELOCITY)
                {
                    const acceleration:Vector2D = Get_Acceleration(subgraph.center_of_collapse, vertex_to_center); 

                    vertex.collapse_velocity = Add_Vectors(vertex.collapse_velocity, acceleration);
                    vertex.coord = Add_Vectors(vertex.coord, vertex.collapse_velocity);
                }
                else 
                    Cancel_Velocity(vertex, subgraph.center_of_collapse.coord)
            }
        };

            const Update_Vertices_View_In_Collpase = (vertex:Vertex, center_of_collapse:Vertex, vertex_to_center:Vector2D) =>
            {
                if (Vector_Are_Equals(vertex.coord, center_of_collapse.coord))
                    vertex.color = 'transparent';
                else if (Get_Vector_Norm(vertex_to_center) < ZONE_CHANGE_VERTEX_VIEW)
                {
                    vertex.text_to_display = "";
                    vertex.layer = center_of_collapse.layer;
                    vertex.color = center_of_collapse.color;
                }

                Adjust_Sizes_To_Collapse(vertex, center_of_collapse);
            };

                const Adjust_Sizes_To_Collapse = (vertex:Vertex, center_of_collapse:Vertex) => 
                {
                    vertex.size -= (vertex.size - center_of_collapse.size) * 0.01;
                    if (vertex.hypergraph_ring_size - 1 > 0)
                        vertex.hypergraph_ring_size -= vertex.hypergraph_ring_size * 0.01;
                };

            const Cancel_Velocity = (vertex:Vertex, center_of_collapse_coord:Vector2D) => 
            {
                vertex.collapse_velocity.x = 0;
                vertex.collapse_velocity.y = 0;
                vertex.coord.x = center_of_collapse_coord.x;
                vertex.coord.y = center_of_collapse_coord.y;
            };
        
        const Vertex_Already_Collapse = (subgraph:Subgraph):boolean =>
        {
            for (const vertex of subgraph.subgraph_vertices)
                if (vertex.coord.x === subgraph.center_of_collapse.coord.x && 
                    vertex.coord.y === subgraph.center_of_collapse.coord.y)
                    return (true);
        
            return (false);
        };



    const All_Vertices_Collapsed = (vertex_arr:Vertex[], center_of_collapse:Vertex):boolean => 
    {
        for (const vertex of vertex_arr)
            if (!Vector_Are_Equals(vertex.coord, center_of_collapse.coord))
                return (false);

        return (true);
    };



    const Update_Edge_State = (subgraph:Subgraph) => 
    {
        for (const edge of subgraph.subgraph_edges)
        {   
            if (edge.current_animation_step <= EDGE_MAX_DRAWING_STEPS)
            {
                if (edge.current_animation_step === EDGE_MAX_DRAWING_STEPS - 1)
                {
                    edge.current = edge.to;
                    edge.edge_complete = true;
                }	
                else
                {
                    let y_edge_length:number = (edge.to.coord.y - edge.from.coord.y);
                    let x_edge_length:number = (edge.to.coord.x - edge.from.coord.x);
                    let edge_slope:number = ( y_edge_length / x_edge_length);
                    let step:number = (x_edge_length / EDGE_MAX_DRAWING_STEPS);
                    
                    edge.current.coord.x = edge.from.coord.x + edge.current_animation_step * step;
                    edge.current.coord.y = (edge_slope * (edge.current.coord.x - edge.from.coord.x)) + edge.from.coord.y;
                }
                edge.current_animation_step++;
            }
        }
    };



    const Draw_Vertex = (canvas_context:CanvasRenderingContext2D, vertex:Vertex) => 
    {
        let gap_text_vertex:number;

        if (vertex.hypergraph_ring_size !== 0)
        {
            gap_text_vertex = vertex.hypergraph_ring_size + 3;
        }
        else 
            gap_text_vertex = 10;

        canvas_context.beginPath();
        canvas_context.arc(vertex.coord.x, vertex.coord.y, vertex.size, 0, 2 * Math.PI);
        canvas_context.fillStyle = vertex.color;
        canvas_context.fill();
        canvas_context.fillText(vertex.text_to_display, vertex.coord.x + gap_text_vertex, vertex.coord.y + gap_text_vertex);
    };

    const Draw_Edge = (canvas_context:CanvasRenderingContext2D, edge:Edge) => 
    {
        canvas_context.beginPath(); 
        canvas_context.moveTo(edge.from.coord.x, edge.from.coord.y);
        canvas_context.lineTo(edge.current.coord.x, edge.current.coord.y);
        canvas_context.lineWidth = 0.7;
        canvas_context.strokeStyle = edge.edge_color;
        canvas_context.stroke();
    };

    const Draw_Halo = (canvas_context:CanvasRenderingContext2D, vertex:Vertex) =>
    {
        if (vertex.current_hypergraph_ring_size < vertex.hypergraph_ring_size)
            vertex.current_hypergraph_ring_size += 1;

        canvas_context.beginPath();
        canvas_context.arc(vertex.coord.x, vertex.coord.y, vertex.current_hypergraph_ring_size, 0, 2 * Math.PI);
        canvas_context.strokeStyle = vertex.color;
        canvas_context.stroke();
    }
