import { Vector2D } from "../../Types/graphAnimation_types";

export const Get_Vector_Norm = (vector:Vector2D):number => 
{
	return (Math.sqrt((vector.x * vector.x) + (vector.y * vector.y)));
};

export const Get_Unit_Vector = (vector:Vector2D):Vector2D =>   
{
	const vector_norme:number = Get_Vector_Norm(vector);

	return ({ x: vector.x / vector_norme, 
			  y: vector.y / vector_norme });
}

export const Mult_Vector_Scalar = (vector:Vector2D, scalar:number):Vector2D => 
{
	return ({x: vector.x * scalar, y: vector.y * scalar})
};

export const Add_Vectors = (vector_1:Vector2D, vector_2:Vector2D):Vector2D => 
{
	return ({ x: vector_1.x + vector_2.x,
			  y: vector_1.y + vector_2.y });
};

export const Vector_Are_Equals = (vector_1:Vector2D, vector_2:Vector2D):boolean => 
{
	return ((vector_1.x === vector_2.x) && (vector_1.y === vector_2.y));
};