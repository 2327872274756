import React from "react";

import "./css/App.css";

import AnimationGraph from "./Components/AnimationGraph/AnimationGraph";
import NavBar from "./Components/NavBar/NavBar";
import { QuotesDisplayer } from "./Components/Quotes/QuotesDisplayer";
import SecondBanner from "./Components/SecondBanner/SecondBanner";
import ThirdBanner from "./Components/ThirdBanner/ThirdBanner";
import FourthBanner from "./Components/FourthBanner/FourthBanner";

import logo from "./Image/Logo/LEDRWHITEPNG.png";

function App() {
  return (
    <div className="App">
      <div className="BackAnimation">
        <AnimationGraph />
      </div>

      <div className="MainContainer">
        <div className="HeaderContainer">
          <div className="NavContainer">
            <div className="CorpLogoContainer">
              <img className="CorpLogo" src={logo} alt="logo" />
            </div>

            <span className="NavLinksContainer">
              <a
                className="HeaderLinks"
                href="https://docs.ledr.io/"
                target="_blank"
                rel="noreferrer"
              >
                Documentation
              </a>
            </span>
          </div>

          <div style={{ position: "absolute", width: "100%", height: "100vh" }}>
            <div className="QuoteDisplay">
              <QuotesDisplayer />
            </div>
          </div>
        </div>

        <div className="NavBarContainer">
          <NavBar />
        </div>

        <div className="FirstBanner">
          <p className="FirstBannerText">
            Harnessing the power of collective intelligence to aid
            decision-making​
          </p>
        </div>
        <div className="Panel">
          <SecondBanner />
        </div>
        <div className="Panel">
          <ThirdBanner />
        </div>
        <div className="Panel">
          <FourthBanner />
        </div>
      </div>

      <div className="FooterContainer">
        <div className="FooterShape">
          <a
            className="FooterLinks"
            href="mailto:connect@ledr.io"
            target="_blank"
            rel="noreferrer"
          >
            Contact us
          </a>
          <a
            className="FooterLinks"
            href="https://docs.ledr.io/"
            target="_blank"
            rel="noreferrer"
          >
            Documentation
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
