import React, { useEffect, useRef, useState } from 'react';

import { Canvas_Manager } from '../../ts_Files/GraphAnimation/Functions/canvas_manager';

const AnimationGraph:React.FunctionComponent = () => {

    const canvas = useRef<HTMLCanvasElement | null>(null);
    const [context, Set_Context] = useState<CanvasRenderingContext2D | null>();

    const handleResizing = () =>
    {
        if (canvas.current)
        {
            canvas.current.width = window.innerWidth - 12;
            canvas.current.height = window.innerHeight;
        }
    };

    window.addEventListener('resize', handleResizing)

    useEffect(() => {
        if (canvas.current)
            Set_Context(canvas.current.getContext("2d"));
    },[]);

    useEffect(() => {
        if (canvas && context)
            Canvas_Manager(context);
    }, [context])

    return (
        <div>
            <canvas ref={canvas}
                    width={window.innerWidth - 12}
                    height={window.innerHeight}
                    style={{background: 'rgb(15, 22, 53)'}}>
            </canvas>
        </div>
    );
};

export default AnimationGraph;