import React, { useEffect, useState } from 'react';

import "./QuotesDisplayer.css";

interface Quotes {
    Quote:string,
    Author:string,
    AuthorTitle:string
};

export const QuotesDisplayer:React.FunctionComponent = () => 
{
    const [CurrentQuoteIndex, setCurrentQuoteIndex] = useState<number>(0);

    const Quotes:Quotes[] = [ { Quote:"Information is the oil of the 21st century, and analytics is the combustion engine",
                                Author:"– Peter Sondergaard – ",
                                AuthorTitle:"EVP Research & Advisory, Gartner Inc."},
                              { Quote: "Privacy is one of the biggest problems in this new electronic age.",
                                Author: "– Andy Grove –​",
                                AuthorTitle: "ex-CEO Intel​"},
                              { Quote: "Data is the pollution problem of the information age, and protecting privacy is the environmental challenge.",
                                Author: "– Bruce Schneier –​",
                                AuthorTitle: "Harvard Kennedy School" },
                              { Quote: "People have entrusted us with their most personal information. We owe them nothing less than the best protections that we can possibly provide.",
                                Author: "– Tim Cook –​",
                                AuthorTitle: "CEO Apple"},
                              { Quote: "Privacy is not something that I’m merely entitled to, it’s an absolute prerequisite.",
                                Author: "– Marlon Brando –",
                                AuthorTitle: ""} ];

  useEffect(() => {
    setInterval(() => {
      setCurrentQuoteIndex((CurrentQuoteIndex) => ((CurrentQuoteIndex + 1)%5));
    }, 10000);
  }, []);

  useEffect(() => {
    console.log(CurrentQuoteIndex);
  }, [CurrentQuoteIndex]);

    return (
        <div key={Math.random()}>
          <div className="QuotesBackground" >
            <p className="Quote" >{Quotes[CurrentQuoteIndex].Quote}</p>
            <p className="Author" >{Quotes[CurrentQuoteIndex].Author}</p>
            <p className="AuthorTitle" >{Quotes[CurrentQuoteIndex].AuthorTitle}</p> 
          </div>
        </div>
    );
};
