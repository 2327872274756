import React from "react";
import CSS from "csstype";
import "./Compass.css";

import compass_1 from "../../Image/Animation/External_cercle.svg";
import compass_2 from "../../Image/Animation/Middle_cercle.svg";

interface State_Compass
{
	angle_x:number,
	angle_y:number,
}

class Compass extends React.Component<State_Compass, State_Compass>
{
	constructor(props:State_Compass, state:State_Compass)
	{
		super(props, state);
		this.state =
		{
			angle_x: this.props.angle_x,
			angle_y: this.props.angle_y,
		}
		window.addEventListener("mousemove", this.handleMouseMove.bind(this));
	}

	handleMouseMove(event:MouseEvent)
	{
		if (-event.screenX < window.innerWidth / 2)
		{
			this.setState(
			{
				angle_x: this.props.angle_x + (event.screenX / window.innerWidth) * 2,
				angle_y: this.props.angle_y - (event.screenY / window.innerHeight) * 2,
			});
		}
	}

	getStyleCompass(depth:number):CSS.Properties
	{
		return (
			{
				transform: "perspective(" + depth + "px) " +
						   "rotateX(" + this.state.angle_y + "deg) " +
						   "rotateY(" + this.state.angle_x + "deg)",
				transition: "all 150ms linear 0s",
			}
		);
	}

	render()
	{
		return (			
			<div className="container">
				<div className="Compass" style={this.getStyleCompass(100)}>
					<div className="CompassPartsContainer">
						<img className="CompassParts" src={compass_1} alt="" />
					</div>
					<div className="CompassPartsContainer">
						<img className="CompassParts" src={compass_2} alt="" />
					</div>
				</div>

				<div style={{position:"relative", top:"23%", left:"20vw"}}>
					<div className="cubeExt">
						<div className="faceExt frontExt"></div>
						<div className="faceExt backExt"></div>
						<div className="faceExt topExt"></div>
						<div className="faceExt bottomExt"></div>
						<div className="faceExt leftExt"></div>
						<div className="faceExt rightExt"></div>
						<div className="cubeInt">
							<div className="faceInt frontInt"></div>
							<div className="faceInt backInt"></div>
							<div className="faceInt topInt"></div>
							<div className="faceInt bottomInt"></div>
							<div className="faceInt leftInt"></div>
							<div className="faceInt rightInt"></div>
						</div>
					</div>
				</div>
			</div>
			
		);
	}
}

export default Compass;
