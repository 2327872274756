import { Vertex } from "../../Classes/Vertex_class";
import { Vector2D } from "../../Types/graphAnimation_types";
import { Get_Unit_Vector, Get_Vector_Norm, Mult_Vector_Scalar } from "./vector_utils";

const GRAVITATIONAL_CONST = 2000;

export const Get_Acceleration = (attractor:Vertex, vertex_to_center:Vector2D):Vector2D => 
{
	const gravity_unit_vector:Vector2D = Get_Unit_Vector(vertex_to_center);
	const gravity_norm: number = Get_Vector_Norm(vertex_to_center);
	const attractor_mass:number = attractor.size;
	const acceleration_norm:number = (attractor_mass / (Math.pow(gravity_norm, 1.75))) * GRAVITATIONAL_CONST;

	return (Mult_Vector_Scalar(gravity_unit_vector, acceleration_norm));
};